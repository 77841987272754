import { Controller } from "stimulus";
import { ajax } from "@rails/ujs";

export default class extends Controller {

  static targets = [
    'hiddenOffset',
    'hiddenDestroy',
    'datePickerInput',
    'numberInput',
  ];

  static values = {
    startDate: String,
    url: String,
    method: String,
    envelope: String
  }

  connect() {
    this.numberInputTarget.addEventListener('blur', this._syncCalendar.bind(this));

    if (this.hasDatePickerInputTarget) {
      this.datePickerInputTarget.addEventListener('change', this._syncNumber.bind(this));
    }
  }

  clearDates(event) {
    event.preventDefault();

    this._dispatchNumberChange('');
    this._dispatchDatePickerChange('');

    this._destroy(false);
  }

  _syncCalendar(event) {
    let newOffset = event.target.value;

    if (newOffset === '' || newOffset === undefined) {
      this._dispatchDatePickerChange('');
      this._destroy(false);
      this._postChanges(null, true);

      return;
    }

    if (this.startDateValue !== '') {
      const newDueDate = this._calculateNewDueDate(newOffset);
      this._dispatchDatePickerChange(this._formatCalendarInputDate(newDueDate));
    }

    this._destroy(true);
    this._setHiddenOffsetValue(newOffset);
    this._postChanges(newOffset);
  }

  _syncNumber() {
    if (this.datePickerInputTarget.value === '') {
      this._dispatchNumberChange('');
      this._destroy(false);
      this._postChanges(null, true);

      return;
    }

    const newOffset = this._calculateOffset();
    this._dispatchNumberChange(newOffset);

    this._destroy(true);
    this._setHiddenOffsetValue(newOffset);
    this._postChanges(newOffset);
  }

  _calculateNewDueDate(offset) {
    let programStartDate = new Date(this.startDateValue);

    return new Date(
      programStartDate.setDate(programStartDate.getDate() + parseInt(offset))
    );
  }

  _calculateOffset() {
    let programStartDate = new Date(this.startDateValue);

    let newDueDate = new Date(this.datePickerInputTarget.value);
    let oneDay = 1000 * 3600 * 24;
    let diffTime = newDueDate.getTime() - programStartDate.getTime();
    let diffDays = diffTime / oneDay;

    return diffDays;
  }

  _formatCalendarInputDate(date) {
    // return format "2021-07-30"
    return date.toISOString(true).slice(0,10);
  }

  _dispatchDatePickerChange(value) {
    if (this.hasDatePickerInputTarget) {
      this.datePickerInputTarget.dispatchEvent(new CustomEvent('promote:datepicker-change', {
        detail: { value: value }
      }));
    }
  }

  _dispatchNumberChange(value) {
    if (this.hasNumberInputTarget) {
      this.numberInputTarget.dispatchEvent(new CustomEvent('change', {
        detail: { value: value }
      }));
    }
  }

  _setHiddenOffsetValue(value) {
    if (this.hasHiddenOffsetTarget) {
      this.hiddenOffsetTarget.value = value + ' days';
    }
  }

  _destroy(shouldDestroy) {
    if (this.hasHiddenDestroyTarget) {
      this.hiddenDestroyTarget.disabled = shouldDestroy;
    }
  }

  _postChanges(offset, destroy = false) {
    if (this.urlValue === '' || this.methodValue === '' || this.envelopeValue === '') {
      console.error('Missing values for url, method or envelope');
      return;
    }

    const body = new FormData();
    body.append(`${this.envelopeValue}[offset]`, `${offset} days`);
    body.append(`${this.envelopeValue}[anchor]`, 'start_at');

    if (destroy) {
      body.append(`${this.envelopeValue}[_destroy]`, true);
    }

    ajax({
      url: this.urlValue,
      type: this.methodValue,
      data: body,
      headers: {
        'Content-Type': 'application/json'
      },
      dataType: "script",
      success: (res) => {}
    });
  }
}
