import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["input", "increase", "decrease"];

  static values = {
    min: Number,
    max: Number,
    step: Number,
    leadingZero: Boolean
  }

  connect() {
    this.inputTarget.addEventListener('blur', this._onBlur.bind(this));
    this.inputTarget.addEventListener('change', this._onChange.bind(this));
  }

  onKeyDown(event) {
    const key = event.key;
    const allowedKeys = ['ArrowLeft', 'ArrowRight',
      'ArrowUp', 'ArrowDown', 'Enter', 'Tab'];

    if (!allowedKeys.includes(key) && (isNaN(parseInt(key)) || event.shiftKey)) {
      event.preventDefault();
    }

    let currentValue = this.inputTarget.value;

    if (key === 'ArrowUp') {
      this.increase();
    } else if (key === 'ArrowDown') {
      this.decrease();
    } else if (key === 'Enter') {
      this.inputTarget.blur();
    } else if (key === 'Backspace') {
      this.inputTarget.value = currentValue.slice(0, -1);
    }
  }

  increase() {
    if (this.inputTarget.value === '') {
      this._setInitialValue();
    } else {
      this._increaseInputValue();
    }

    this._dispatchBlurEvent();
  }

  decrease() {
    if (this.inputTarget.value === '') {
      this._setInitialValue();
    } else {
      this._decreaseInputValue();
    }

    this._dispatchBlurEvent();
  }

  // This method is used when calling with a custom change event
  // Change event dispatched from the component itself will not contain event.detail and is
  // therefore ignored by the method
  // Example of custom event dispatch:
  // <inputTarget>.dispatchEvent(new CustomEvent('change', { detail: { value: <value> } }));
  _onChange(event) {
    if (!event.detail) {
      return;
    }

    const value = event.detail.value;

    if (value === '') {
      this._clearValue();
    } else if (value === 0) {
      this._setInitialValue();
    } else {
      this.inputTarget.value = this._addLeadingZero(value);
    }
  }

  _onBlur() {
    let value = this.inputTarget.value;

    if (value === '') {
      return;
    }

    if (this.maxValue && value > this.maxValue) {
      value = this.maxValue;
    } else if (this.minValue && value < this.minValue) {
      value = this.minValue;
    } else {
      value = parseInt(value);
    }

    this.inputTarget.value = this._addLeadingZero(value);
  }

  _setInitialValue () {
    let value;

    if (this.minValue) {
      value = this.minValue;
    } else {
      value = 0;
    }

    this.inputTarget.value = this._addLeadingZero(value);
  }

  _clearValue() {
    this.inputTarget.value = '';
  }

  _increaseInputValue() {
    let value = parseInt(this.inputTarget.value);

    if (this.stepValue) {
      value = this._closestStepValueAbove(value);
    } else {
      value++;
    }

    if (this.maxValue && value > this.maxValue) {
      return;
    }

    this.inputTarget.value = this._addLeadingZero(value);
  }

  _decreaseInputValue() {
    let value = parseInt(this.inputTarget.value);

    if (this.stepValue) {
      value = this._closestStepValueBelow(value);
    } else {
      value--;
    }

    if ((this.minValue && value < this.minValue) || value < 0) {
      return;
    }

    this.inputTarget.value = this._addLeadingZero(value);
  }

  _addLeadingZero(value) {
    if (!this.leadingZeroValue) {
      return value;
    }

    return value < 10 ? `0${value}` : value;
  }

  _closestStepValueAbove(value) {
    let threshold = this._thresholdValue(value);

    if (value < threshold) {
      return threshold;
    } else {
      return threshold + this.stepValue;
    }
  }

  _closestStepValueBelow(value) {
    let threshold = this._thresholdValue(value);

    if (value > threshold) {
      return threshold;
    } else {
      return threshold - this.stepValue;
    }
  }

  _thresholdValue(value) {
    let initialValue = this.minValue || 0;

    return Math.floor(value/this.stepValue) * this.stepValue + initialValue;
  }

  _dispatchBlurEvent() {
    this.inputTarget.dispatchEvent(new Event('blur'));
  }
}
